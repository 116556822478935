@import "../index";

.ResponsiveNavbar {

    &-container {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        //border-top: 1px solid $primary;
        background: $primary;
        padding: 10px 5px;
        height: 3.5rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        a {
            width: 30px;
        }
    }
}
