html, body, #root {
    height: 100%;
    background: #eee;
}

#root {
    position: relative;
    display: flex;
    flex-direction: column;
}

.reports-tabs {
    margin-bottom: 10px !important;
    color: #00b5ad;
}

.reports-tabs.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-color: #00b5ad !important;
    color: #00b5ad !important;
    border-radius: 5px;
    cursor: pointer;
}
.reports-tabs.nav-tabs .nav-link, .nav-tabs .nav-item.show .nav-link {
    border-radius: 5px;
    cursor: pointer;
}

.report-resume__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.report-resume__container div:nth-child(2n) {
    margin: 0 10px;
}

@media (max-width: 768px) {
    .report-resume__container {
        flex-direction: column;
    }

    .report-resume__container div:nth-child(2n) {
        margin: 10px 0;
    }
}
