$primary: #00b5ad;

$theme-colors: (
    "primary": #00b5ad,
);

@import "../node_modules/bootstrap/scss/bootstrap";

@import "./styles/modal";
@import "./styles/react-date-picker";
@import "./styles/utils";
@import "./styles/transaction-item";
@import "./styles/category-item";
@import "./styles/account-item";

.table {
    background: #fff;
}
